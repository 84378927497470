import React from "react";
import Skeleton from "react-loading-skeleton";

const CustomSkeleton = ({ tableHeaderList }) => {
  return (
    <>
      <table className="table align-items-center mb-0">
        <thead style={{ background: "white" }}>
          <tr>
            {tableHeaderList.map((index) => (
              <th
                key={index}
                className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                <Skeleton
                  style={{
                    width: "100%",
                    height: "30px",
                    borderRadius: "10px",
                  }}
                />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((i) => {
            return (
              <tr key={i}>
                {tableHeaderList.map((index) => (
                  <td key={index} className="align-middle px-4 ">
                    <span className="text-secondary text-sm font-weight-bold text-center ">
                      <Skeleton
                        style={{
                          height: "25px",
                          width: "170px",
                          borderRadius: "10px",
                        }}
                      />
                    </span>
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default CustomSkeleton;
