const TemplateArray = [
  {
    id: "1",
    icon: <i className="fas fa-landmark " aria-hidden="true" />,
    name: "Intro",
  },
  {
    id: "2",
    icon: <i className="fas fa-landmark " aria-hidden="true" />,
    name: "Reality Check",
  },
  {
    id: "3",
    icon: <i className="fas fa-landmark " aria-hidden="true" />,
    name: "Behavioral Tendencies",
  },
  {
    id: "4",
    icon: <i className="fas fa-landmark " aria-hidden="true" />,
    name: "Scenario",
  },
  {
    id: "5",
    icon: <i className="fas fa-landmark " aria-hidden="true" />,
    name: "Actions",
  },
  {
    id: "6",
    icon: <i className="fas fa-landmark " aria-hidden="true" />,
    name: "Reflection",
  },
  {
    id: "7",
    icon: <i className="fas fa-landmark " aria-hidden="true" />,
    name: "Skill Quiz",
  },
  {
    id: "8",
    icon: <i className="fas fa-landmark " aria-hidden="true" />,
    name: "Journal",
  },
  {
    id: "9",
    icon: <i className="fas fa-landmark " aria-hidden="true" />,
    name: "Wrap Up",
  },
  {
    id: "10",
    icon: <i className="fas fa-landmark " aria-hidden="true" />,
    name: "Journey Quiz",
  },
  {
    id: "11",
    icon: <i className="fas fa-landmark " aria-hidden="true" />,
    name: "Journey Journal",
  },
  {
    id: "12",
    icon: <i className="fas fa-landmark " aria-hidden="true" />,
    name: "Final Wrap Up",
  },
];

const ExerciseTemplateArray = [
  {
    id: "1",
    icon: <i className="fas fa-landmark " aria-hidden="true" />,
    name: "Practice",
  },
  {
    id: "2",
    icon: <i className="fas fa-landmark " aria-hidden="true" />,
    name: "Reflect",
  },
  {
    id: "3",
    icon: <i className="fas fa-landmark " aria-hidden="true" />,
    name: "Act",
  },
];
export { TemplateArray, ExerciseTemplateArray };
