import React from "react";

const PageNotFound = () => {
  return (
    <div className="page_not_found">
      <div className="number">404</div>
      <div className="text">
        <br />
        Page Not Found
      </div>
    </div>
  );
};

export default PageNotFound;
