import React from "react";
import Skeleton from "react-loading-skeleton";

const StepListingSkeleton = () => {
  return [1, 2, 3, 4, 5].map((item, index) => {
    return (
      <div className="col">
        <div className="steps-wrapper">
          <div className="steps-heading">
            <Skeleton style={{ width: "100%", height: "40px" }} />
          </div>
          <div className="steps-content-blk">
            <div className="steps-card-img cursor-pointer">
              <Skeleton style={{ width: "130px", height: "100%" }} />
            </div>
            <div className="steps-detail">
              <Skeleton style={{ width: "100%", height: "30px" }} />
              <Skeleton style={{ width: "100%", height: "10px" }} />
              <Skeleton style={{ width: "100%", height: "10px" }} />
            </div>
          </div>
        </div>
      </div>
    );
  });
};

export default StepListingSkeleton;
