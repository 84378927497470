import React from "react";
import { ClipLoader } from "react-spinners";
import TempLayoutSkeleton from "../../utils/skeleton/templateSkeleton";

const TempLayout = ({
  children,
  handleEditForm,
  editTemplate,
  handleApply,
  loading,
  dataLoading,
}) => {
  return (
    <div className="card-wrapper" id="my-element">
      <div className="row mb-0">
        <div className="col-12 d-flex justify-content-between">
          <div className="flex-start-btn mb-4">
            <h2 className="h5"> Screen Templates</h2>
          </div>
          <div className="flex-end-btn">
            <button
              type="button"
              className="btn btn-green mb-0 text-uppercase "
              onClick={() => handleEditForm()}
            >
              {editTemplate ? "Cancel" : "Edit"}
            </button>
          </div>
        </div>
      </div>
      {dataLoading ? <TempLayoutSkeleton /> : children}
      <div className="row">
        <div className="col-12">
          <div className="flex-end-btn mt-2">
            {editTemplate ? (
              <button
                className="btn btn-lg btn-green  w-10 mt-3 mb-0"
                onClick={handleApply}
                disabled={loading}
              >
                {loading ? (
                  <ClipLoader color="#0d6efd" size={16} />
                ) : (
                  "Apply Changes"
                )}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TempLayout;
