import React from "react";
import { truncateText } from "../../../utils/helpers";

const JourneyListRow = ({ data, onView }) => {
  return (
    <>
      {data?.map((item, index) => {
        return (
          <tr
            className="cursor-pointer"
            onClick={() => onView(item)}
            key={index}
          >
            <td className="align-middle px-4">
              <img
                src={item?.image}
                className="avatar avatar-sm me-3"
                alt="..logo"
              />
            </td>
            <td className="align-middle px-4 ">
              <span className="text-secondary text-sm font-weight-bold text-center">
                {item?.name}
              </span>
            </td>
            <td className="align-middle px-4 ">
              {truncateText(item?.description)}
            </td>
            <td className="align-middle  ">
              <span
                style={{ width: "100px" }}
                class={
                  item?.status === 1
                    ? "badge badge-sm bg-gradient-success cursor-pointer"
                    : "bg-gradient-secondary badge badge-sm cursor-pointer"
                }
              >
                {item?.status === 1 ? "Active" : "Suspended"}
              </span>
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default JourneyListRow;
