import React from "react";
import TableHeader from "./tableHeader";
import CustomSkeleton from "../../utils/skeleton/customSkeleton";
import Pagination from "./pagination";
import NotFound from "../notFound";

const CustomTable = ({
  heading,
  tableHeaderList,
  children,
  isLoading,
  currentPage,
  handleNextPage,
  handlePrevPage,
  handleRowLimitChange,
  totalPages,
  countData,
  addBtn,
  addBtnTitle,
  addBtnAction,
  tableData,
}) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="card mb-4">
          <div className="card-header tableHeader">
            <h6>{heading}</h6>
            {/* <input
              type="text"
              className="form-control w-auto"
              placeholder="Search here..."
            ></input> */}
            <div>
              {addBtn === true ? (
                <button
                  type="button"
                  className="btn btn-green w-100  mb-0  text-uppercase "
                  onClick={addBtnAction}
                >
                  {addBtnTitle}
                </button>
              ) : null}
            </div>
          </div>
          <div className="card-body px-0 pt-0 pb-2">
            <div className="table-responsive p-0">
              {isLoading ? (
                <CustomSkeleton tableHeaderList={tableHeaderList} />
              ) : tableData?.length === 0 ? (
                <NotFound />
              ) : (
                <>
                  <table className="table align-items-center mb-0">
                    <TableHeader data={tableHeaderList} />
                    <tbody>{children}</tbody>
                  </table>
                </>
              )}
            </div>
            {isLoading ||
              (tableData?.length === 0 ? null : (
                <Pagination
                  currentPage={currentPage}
                  handleNextPage={handleNextPage}
                  handlePrevPage={handlePrevPage}
                  handleRowLimitChange={handleRowLimitChange}
                  totalPages={totalPages}
                  countData={countData}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomTable;
