/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from "react";
import ImagesUrl from "../../utils/constants/images";
import TempLayout from "../../components/UI/tempLayout";
import ScreenLayout from "../../components/UI/screenLayout";
import ENDPOINTS from "../../services/endPoints";

import QUERY_KEYS from "../../services/queryKeys";
import usePost from "../../hooks/usePost";
import { toast } from "react-toastify";
import { uniqueIdGenerator } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../routes/routePath";
import { FadeLoader } from "react-spinners";
import userGlobalConfig from "../../utils/constants/globalConfig";

const Actions = ({ props }) => {
  const { data, templateId, handleEditForm, editTemplate, exerciseId } = props;
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInput = useRef(null);

  const [form3, setForm3] = useState({
    formId: uniqueIdGenerator(),
    formType: "primary",
    description:
      "Develop this skill by taking action. You get out what you put in, and you learn the best by doing. Ready?",
    buttonText: "Let's Go",
  });

  const [form4, setForm4] = useState({
    formId: uniqueIdGenerator(),
    formType: "thoughts",
    question:
      "Select the suggested action to save it to your list and complete it now or whenever you want.",

    options: [
      {
        title: "Find a role model",
        description:
          "Pick a role model (of any gender) whom you admire for their confidence. Pinpoint their behaviors or attributes that make them appear confident and try adopting some. If you can, ask them for advice or guidance on enhancing self-confidence.",
      },
    ],
    returnHomeButtonText: "Skip & Exit",
    buttonText: "Save to my Actions",
  });

  const [finalForm, setFinalForm] = useState({
    formId: uniqueIdGenerator(),
    formType: "final square",
    image: {
      form_unique_id: "",
      added_image: "",
    },
    title: "Success !",
    description:
      "Your selection has been successfully added to your Actions list. ",
    buttonText: "Go to my actions",
  });

  const nav = useNavigate();

  const { mutateAsync: addImagesMutateAsync, isLoading: isLoadingAddImages } =
    usePost(
      ENDPOINTS.ADD_TEMPLATE_FORM_IMAGE,
      QUERY_KEYS.ADD_TEMPLATE_FORM_IMAGES_QUERY_KEY
    );

  const {
    mutateAsync: addTemplateMutateAsync,
    isLoading: isLoadingAddTemplate,
  } = usePost(
    ENDPOINTS.ADD_EXERCISE_TEMPLATE_FORM,
    QUERY_KEYS.ADD_TEMPLATE_EXERCISE_DATA_QUERY_KEY
  );

  const handleFileInput = (target) => {
    setSelectedFile(target.files[0]);
    target.value = "";
  };

  const handleSelectUploadImage = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleOptionForm = (event, index, key) => {
    setForm4((prevForm) => {
      prevForm.options[index][key] = event.target.innerText;
      return { ...prevForm };
    });
  };

  const handelApply = (imageResponse) => {
    const formData = [
      {
        name: "Actions",
        form_json: [
          form3,
          form4,
          {
            ...finalForm,
            image: imageResponse?.data ?? finalForm.image,
          },
        ],
      },
    ];

    if (imageResponse !== null) {
      setFinalForm({
        ...finalForm,
        image: imageResponse?.data,
      });
    }
    uploadFormJson(formData);
  };
  const uploadFormJson = async (formData) => {
    const params = {
      exercise_id: exerciseId,
      type: templateId,
      template_json: formData,
    };

    try {
      const response = await addTemplateMutateAsync(params);

      if (response.statusCode === 200) {
        toast.success(response?.message);
        handleEditForm();
        nav(RoutePath.SKILL_DETAIL.path);
        localStorage.removeItem(userGlobalConfig.EXERCISE_ID);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error?.response.data?.message);
    }
    //add upload form api
  };

  const handleUpload = async (callback) => {
    if (selectedFile == null) {
      callback(null);
    } else {
      const imageId = finalForm?.image?.form_unique_id ?? null;
      const formData = new FormData();
      formData.append("_id", exerciseId);
      formData.append("template_id", templateId);
      formData.append("form_unique_id", imageId);
      formData.append("image", selectedFile);
      try {
        const response = await addImagesMutateAsync(formData);

        if (response.statusCode === 200) {
          callback(response);
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    if (data?.type == 3) {
      const forms = data?.exercise_json;

      if (forms && forms?.length !== 0) {
        setForm3(forms[0]?.form_json[0]);
        setForm4(forms[0]?.form_json[1]);
        setFinalForm(forms[0]?.form_json[2]);
      } else {
        handleEditForm(true);
      }
    }
  }, [data]);

  return (
    <TempLayout
      handleEditForm={handleEditForm}
      editTemplate={editTemplate}
      handleApply={() => handleUpload(handelApply)}
      loading={isLoadingAddTemplate || isLoadingAddImages}
    >
      <section className="screen-section">
        <div className="container-fluid gx-0">
          <div className="row gap-3">
            <div className="col-12">
              <div className="screen-wrapper">
                <ScreenLayout name="Action">
                  <div className="screen-body">
                    <div
                      className="checkbox-wrapper"
                      style={{ maxHeight: "395px", marginTop: "0px" }}
                    >
                      <div className="checkbox-list">
                        <div className="screen-content">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setForm3({
                                ...form3,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {form3?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setForm3({
                            ...form3,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {form3?.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
                <ScreenLayout name="Action">
                  <div
                    className="checkbox-wrapper"
                    style={{ maxHeight: "395px", marginTop: "0px" }}
                  >
                    <div className="checkbox-list">
                      <div className="sm-content">
                        <p
                          contentEditable={editTemplate}
                          onBlur={(e) => {
                            setForm4({
                              ...form4,
                              question: e.target.innerText,
                            });
                          }}
                        >
                          {form4?.question}
                        </p>
                      </div>
                      <div className="screen-body px-35">
                        <div className="checkbox-wrapper check-alignment-top">
                          {form4?.options?.map((item, index) => {
                            return (
                              <div className="checkbox-list">
                                <input
                                  type="radio"
                                  className="d-none"
                                  name="listradio2"
                                  // id={`checkbox_0${index}`}
                                />
                                <label
                                  htmlFor={`checkbox_0${index}`}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="d-flex gap-3 align-items-center">
                                    <span className="check-box-icon" />
                                    <span
                                      className="radio-heading"
                                      contentEditable={editTemplate}
                                      onBlur={(e) =>
                                        handleOptionForm(e, index, "title")
                                      }
                                    >
                                      {item?.title}
                                    </span>
                                  </div>

                                  <span
                                    className="check-box-text"
                                    contentEditable={editTemplate}
                                    onBlur={(e) =>
                                      handleOptionForm(e, index, "description")
                                    }
                                  >
                                    {item?.description}
                                  </span>
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="screen-footer">
                    <div
                      className="btn-text-style"
                      contentEditable={editTemplate}
                      onBlur={(e) => {
                        setForm4({
                          ...form4,
                          returnHomeButtonText: e.target.innerText,
                        });
                      }}
                    >
                      {form4?.returnHomeButtonText}
                    </div>
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setForm4({
                            ...form4,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {form4?.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
                <ScreenLayout name="Action" notHeaderRequire="true">
                  <div className="screen-body px-35 item-center">
                    <div className="upload-img-blk">
                      <img
                        className="preview-img"
                        src={
                          selectedFile === null
                            ? finalForm?.image?.added_image === ""
                              ? ImagesUrl.shape_image
                              : `https://leadherself-api.csdevhub.com/src/public/form_images/${finalForm?.image?.added_image}`
                            : URL.createObjectURL(selectedFile)
                        }
                        alt="Image"
                      />
                      <div className="upload-img-file">
                        <input
                          type="file"
                          className="d-none"
                          accept="image/*"
                          ref={fileInput}
                          onChange={({ target }) => handleFileInput(target)}
                        />
                        <label
                          htmlFor="ImageUpload"
                          onClick={
                            editTemplate ? handleSelectUploadImage : null
                          }
                        >
                          <i className="fa-solid fa-image" />
                        </label>
                      </div>
                    </div>
                    <div
                      className="checkbox-wrapper"
                      style={{ maxHeight: "300px", marginTop: "0px" }}
                    >
                      <div className="checkbox-list">
                        <div className="screen-content d-flex justify-content-center w-100 ">
                          <p
                            className="ls-font ft-22 lht-32 pb-2 "
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setFinalForm({
                                ...finalForm,
                                title: e.target.innerText,
                              });
                            }}
                          >
                            {finalForm?.title}
                          </p>
                        </div>
                        <div className="screen-content">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setFinalForm({
                                ...finalForm,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {finalForm?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setFinalForm({
                            ...finalForm,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {finalForm?.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
              </div>
            </div>
          </div>
        </div>
      </section>
    </TempLayout>
  );
};

export default Actions;
