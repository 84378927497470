import React from "react";
import Skeleton from "react-loading-skeleton";

const TempLayoutSkeleton = () => {
  return (
    <section className="screen-section">
      <div className="container-fluid gx-0">
        <div className="row gap-3">
          <div className="col-12">
            <div className="screen-wrapper">
              {[0, 1, 2, 3, 4].map((item) => {
                return <Skeleton count={1} className="screen-layout" />;
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TempLayoutSkeleton;
