import * as Yup from "yup";

const addSupportInitialValues = {
  supportType: "",
  supportQuestion: "",
  supportAnswer: "",
};
const addSupportValidationSchema = Yup.object().shape({
  supportType: Yup.string().trim().required("Support is required*"),
  supportQuestion: Yup.string().trim().required("Question is required*"),
  supportAnswer: Yup.string().trim().required("Answer is required*"),
});
export { addSupportInitialValues, addSupportValidationSchema };
