const SettingArray = [
  {
    id: "1",
    icon: <i className="fa-regular fa-address-card" aria-hidden="true" />,
    name: "About Us",
  },
  {
    id: "2",
    icon: <i className="fa-solid fa-circle-info" aria-hidden="true" />,
    name: "Support",
  },
  {
    id: "3",
    icon: <i className="fa-solid fa-scale-balanced" aria-hidden="true" />,
    name: "Terms and Conditions",
  },
  {
    id: "4",
    icon: <i className="fa-solid fa-shield-halved" aria-hidden="true" />,
    name: "Privacy Policy",
  },
];
export { SettingArray };
