/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { truncateText } from "../../utils/helpers";

const JourneyCard = ({ data, onView }) => {
  return (
    <div className="col cursor-pointer" onClick={() => onView(data)}>
      <div className="card card-profile" style={{ height: "100%" }}>
        <img
          src={data?.background_image}
          alt="Image placeholder"
          className="card-img-top"
        />
        <div className="row justify-content-center">
          <div className="col-4 col-lg-4 order-lg-2">
            <div className="mt-n4 mt-lg-n6 mb-0 mb-lg-0 bg-white rounded-3">
              <img
                src={data?.image}
                className="card-logo-img rounded-3 img-fluid border border-5"
              />
            </div>
          </div>
        </div>

        <div className="card-body pt-0">
          <div className="text-center mt-4">
            <h5>{data?.name}</h5>

            <div className="cardcontent">
              <p>{truncateText(data?.description)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JourneyCard;
