const numberOnly = (event) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

function truncateText(text) {
  if (text?.length > 80) {
    return `${text.substring(0, 80)} ..... `;
  } else {
    return text.substring(0, 80);
  }
}

const uniqueIdGenerator = function () {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

export { numberOnly, truncateText, uniqueIdGenerator };
