import * as Yup from "yup";

const addStepInitialValues = {
  stepName: "",
  stepDescription: "",
  stepImage: "",
};
const addStepValidationSchema = Yup.object().shape({
  stepName: Yup.string().trim().required(" Name is required*"),
  stepDescription: Yup.string().trim().required("Description  is required*"),
  stepImage: Yup.string().trim().required("Image is required*"),
});
export { addStepInitialValues, addStepValidationSchema };
