import React from "react";
import Skeleton from "react-loading-skeleton";

const JourneySkeleton = () => {
  return [1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
    return (
      <div className="col">
        <div className="card card-profile">
          <Skeleton
            className="card-img-top"
            style={{ height: "170px", width: "100%" }}
          />
          <div className="row justify-content-center">
            <div className="col-4 col-lg-4 order-lg-2">
              <div className="mt-n4 mt-lg-n6 mb-0 mb-lg-0 bg-white rounded-3">
                <Skeleton className="card-logo-img rounded-3 img-fluid border border-5" />
              </div>
            </div>
          </div>

          <div className="card-body pt-0">
            <div className="text-center mt-4">
              <Skeleton
                style={{ height: "35px", width: "100%", marginBottom: "10px" }}
              />

              <div className="cardcontent">
                <Skeleton style={{ height: "20px", width: "100%" }} />
                <Skeleton style={{ height: "20px", width: "100%" }} />
                <Skeleton style={{ height: "20px", width: "100%" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
};

export default JourneySkeleton;
