const ENDPOINTS = {
  ADMIN_LOGIN: "/admin/login",
  CHANGE_PASSWORD: "/admin/chnagepassword",

  USER_LIST: "/admin/usersManagement",
  USER_DETAIL: "/admin/usersManagement/user/view",
  USER_STATUS: "/admin/usersManagement/user/active-suspend",
  USER_DELETE: "/admin/usersManagement/user",
  USER_EDIT: "/admin/usersManagement/user/edit",

  ADD_JOURNEY: "/admin/journyManagement/journy/add",
  EDIT_JOURNEY: "/admin/journyManagement/journy/edit",
  DETAIL_JOURNEY: "/admin/journyManagement/journy/view",
  STATUS_JOURNEY: "/admin/journyManagement/journy/active-suspend",
  DELETE_JOURNEY: "/admin/journyManagement/journy",
  LIST_JOURNEY: "/admin/journyManagement",

  SKILLS_LIST: "/admin/skilsManagement",
  SKILL_ADD: "/admin/skilsManagement/skill/add",
  SKILL_EDIT: "/admin/skilsManagement/skill/edit",
  SKILL_STATUS: "/admin/skilsManagement/skill/active-suspend",
  SKILL_DETAIL: "/admin/skilsManagement/skill/view",
  SKILL_DELETE: "/admin/skilsManagement/skill",

  STEPS_LIST: "/admin/stepsManagement",
  STEPS_ADD: "/admin/stepsManagement/step/add",
  STEPS_EDIT: "/admin/stepsManagement/step/edit",
  STEPS_STATUS: "/admin/stepsManagement/step/active-suspend",
  STEPS_DETAIL: "/admin/stepsManagement/step/view",
  STEPS_DELETE: "/admin/stepsManagement/step",

  TEMPLATE_IMAGES: "/admin/template/add_image",
  TEMPLATE_DATA_ADD: "/admin/stepsManagement/step/add-template",

  CONTENT: "/admin/content",
  EDIT_CONTENT: "/admin/content/edit_content",

  ADD_ABOUT_US: "/admin/content/add-about-us",
  EDIT_ABOUT_US: "/admin/content/edit-about-us",
  DELETE_ABOUT_US: "/admin/content/delete-about-us",
  ABOUT_US: "/admin/content/about-us",

  SUPPORT_TYPE: "/admin/content/support",
  SUPPORT_FAQ: "/admin/content/faq",
  EDIT_SUPPORT_FAQ: "/admin/content/edit-faq",
  DELETE_SUPPORT_FAQ: "/admin/content/delete-faq",
  ADD_SUPPORT_FAQ: "/admin/content/add-faq",

  EXERCISES_LIST: "/admin/exercisesManagement/list-exercise",
  EDIT_EXERCISE: "/admin/exercisesManagement/exercise/edit",
  DELETE_EXERCISE: "/admin/exercisesManagement/exercise",
  ADD_EXERCISE: "/admin/exercisesManagement/add-exercise",
  VIEW_EXERCISE: "/admin/exercisesManagement/exercise/view",
  EXERCISE_STATUS: "/admin/exercisesManagement/exercise/active-suspend",
  ADD_TEMPLATE_FORM_IMAGE: "/admin/exercisesManagement/add-template-form-image",
  ADD_EXERCISE_TEMPLATE_FORM: "/admin/exercisesManagement/exercise/add-form",
};

export default ENDPOINTS;
