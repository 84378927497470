import "./App.css";

import Navigation from "./routes";
import { AuthProvider } from "./contexts/authContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import { ErrorBoundary } from "react-error-boundary";
import CustomErrorBoundary from "./components/UI/customErrorBoundary";

function App() {
  const queryClient = new QueryClient();
  function fallbackRender({ error, resetErrorBoundary }) {
    return <CustomErrorBoundary />;
  }

  return (
    <ErrorBoundary fallbackRender={fallbackRender}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <Navigation />
          <ToastContainer
            position="top-right"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </AuthProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
