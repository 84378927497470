/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import TempLayout from "../../components/UI/tempLayout";
import ScreenLayout from "../../components/UI/screenLayout";
import ReflectionComponent from "../../components/UI/reflectionComponent";
import usePost from "../../hooks/usePost";
import ENDPOINTS from "../../services/endPoints";
import QUERY_KEYS from "../../services/queryKeys";
import { toast } from "react-toastify";
import { uniqueIdGenerator } from "../../utils/helpers";
import { RoutePath } from "../../routes/routePath";
import { useNavigate } from "react-router-dom";
import ImagesUrl from "../../utils/constants/images";
import CustomModal from "../../components/customModal/customModal";
import TextEditor from "../../components/textEdtior";

const Reflection = ({ props }) => {
  const [editorModal, setEditorModal] = useState(false);
  const {
    data,
    templateId,
    handleEditForm,
    editTemplate,
    refetchStepDetail,
    stepId,
  } = props;
  const [active, setActive] = useState(0);
  const [form1, setForm1] = useState({
    formId: uniqueIdGenerator(),
    formType: "simple continue",
    description:
      "Dive deeper into your opportunities by reflecting on what’s holding you back.",
    buttonText: "Tap To Continue",
  });
  const [form2, setForm2] = useState({
    formId: uniqueIdGenerator(),
    formType: "primary",
    description:
      "There is no correct answer to the following question. Your response will help us recommend resources... Ready?",
    buttonText: "Let's Go",
  });
  const [form3, setForm3] = useState({
    formId: uniqueIdGenerator(),
    formType: "Question",
    question:
      "Which of these feels like the most pressing issue harming your self-confidence today?",
    options: [
      "Imposter syndrome",
      "Negative self-talk",
      "Self-doubt",
      "Not sure",
    ],
    answer: 0,
    buttonText: "Submit option",
  });
  const [resource, setResource] = useState([
    [
      {
        id: uniqueIdGenerator(),
        image: {
          form_unique_id: "",
          added_image: "",
        },
        title: "Article",
        description: "‘How Female Executives Can Overcome Imposter Syndrome’",
        author: "by Sarah Louden ",
        time: "7 min",
        overViewTitle: "Overcoming imposter syndrome",
        overViewDes:
          "<p>In this podcast episode, Lisa Orb Austin delves into the intricate layers of impostor syndrome, shedding light on its pervasive nature and the pathways to overcome it. </p>",
        overViewNote:
          "This resource is third party material. If you want to learn more, you will be redirected to another page outside of the LeadHerself app.",
        overViewButtonText: "Learn more",
        learnMoreLink: "http://www.google.com",
        overViewPrimaryButton: "Return to Journey",
      },
    ],
    [
      {
        id: uniqueIdGenerator(),
        image: {
          form_unique_id: "",
          added_image: "",
        },
        title: "Article",
        description: "‘How Female Executives Can Overcome Imposter Syndrome’",
        author: "by Sarah Louden ",
        time: "7 min",
        overViewTitle: "Overcoming imposter syndrome",
        overViewDes:
          "<p>In this podcast episode, Lisa Orb Austin delves into the intricate layers of impostor syndrome, shedding light on its pervasive nature and the pathways to overcome it. </p>",
        overViewNote:
          "This resource is third party material. If you want to learn more, you will be redirected to another page outside of the LeadHerself app.",
        overViewButtonText: "Learn more",
        learnMoreLink: "http://www.google.com",
        overViewPrimaryButton: "Return to Journey",
      },
    ],
    [
      {
        id: uniqueIdGenerator(),
        image: {
          form_unique_id: "",
          added_image: "",
        },
        title: "Article",
        description: "‘How Female Executives Can Overcome Imposter Syndrome’",
        author: "by Sarah Louden ",
        time: "7 min",
        overViewTitle: "Overcoming imposter syndrome",
        overViewDes:
          "<p>In this podcast episode, Lisa Orb Austin delves into the intricate layers of impostor syndrome, shedding light on its pervasive nature and the pathways to overcome it. </p>",
        overViewNote:
          "This resource is third party material. If you want to learn more, you will be redirected to another page outside of the LeadHerself app.",
        overViewButtonText: "Learn more",
        learnMoreLink: "http://www.google.com",
        overViewPrimaryButton: "Return to Journey",
      },
    ],
    [
      {
        id: uniqueIdGenerator(),
        image: {
          form_unique_id: "",
          added_image: "",
        },
        title: "Article",
        description: "‘How Female Executives Can Overcome Imposter Syndrome’",
        author: "by Sarah Louden ",
        time: "7 min",
        overViewTitle: "Overcoming imposter syndrome",
        overViewDes:
          "<p>In this podcast episode, Lisa Orb Austin delves into the intricate layers of impostor syndrome, shedding light on its pervasive nature and the pathways to overcome it. </p>",
        overViewNote:
          "This resource is third party material. If you want to learn more, you will be redirected to another page outside of the LeadHerself app.",
        overViewButtonText: "Learn more",
        learnMoreLink: "http://www.google.com",
        overViewPrimaryButton: "Return to Journey",
      },
    ],
  ]);

  const [resourceSubForm, setResourceSubForm] = useState([
    {
      formId: uniqueIdGenerator(),
      resourceKey: 0,
      formType: "resources",
      title: "Check out these resources:",
      buttonText: "Return to Journey",
    },
    {
      formId: uniqueIdGenerator(),
      resourceKey: 1,
      formType: "resources",
      title: "Check out these resources:",
      buttonText: "Return to Journey",
    },
    {
      formId: uniqueIdGenerator(),
      resourceKey: 2,
      formType: "resources",
      title: "Check out these resources:",
      buttonText: "Return to Journey",
    },
    {
      formId: uniqueIdGenerator(),
      resourceKey: 3,
      formType: "resources",
      title: "Check out these resources:",
      buttonText: "Return to Journey",
    },
  ]);

  const nav = useNavigate();
  const {
    mutateAsync: addTemplateMutateAsync,
    isLoading: isLoadingAddTemplate,
  } = usePost(
    ENDPOINTS.TEMPLATE_DATA_ADD,
    QUERY_KEYS.TEMPLATE_DATA_ADD_QUERY_KEY
  );
  const { mutateAsync: addImagesMutateAsync, isLoading: isLoadingAddImages } =
    usePost(ENDPOINTS.TEMPLATE_IMAGES, QUERY_KEYS.TEMPLATE_IMAGES_QUERY_KEY);
  const handleResourceSubFormText = (event, index, key) => {
    setResourceSubForm((prevResource) => {
      prevResource[index][key] = event.target.innerText;
      return [...prevResource];
    });
  };
  const addNewResource = () => {
    let newResources = [...resource];

    newResources[form3.answer].push({
      id: uniqueIdGenerator(),
      image: {
        form_unique_id: "",
        added_image: "",
      },
      title: "Article",
      description: "‘How Female Executives Can Overcome Imposter Syndrome’",
      author: "by Sarah Louden ",
      time: "7 min",
      overViewTitle: "Overcoming imposter syndrome",
      overViewDes:
        "In this podcast episode, Lisa Orb Austin delves into the intricate layers of impostor syndrome, shedding light on its pervasive nature and the pathways to overcome it.",
      overViewNote:
        "This resource is third party material. If you want to learn more, you will be redirected to another page outside of the LeadHerself app.",
      overViewButtonText: "Learn more",
      learnMoreLink: "http://www.google.com",
      overViewPrimaryButton: "Return to Journey",
    });

    setResource(newResources);
  };
  const removeResource = (i) => {
    const newResource = [...resource];
    newResource[form3.answer].splice(i, 1);
    setResource(newResource);
    if (i == 0) {
      setActive(0);
    } else setActive(i - 1);
  };

  const handleResourceText = (event, index, key) => {
    setResource((prevResource) => {
      prevResource[form3.answer][index][key] = event.target.innerText;
      return [...prevResource];
    });
  };
  const handleTextEditorResourceText = (event, index, key) => {
    setResource((prevResource) => {
      prevResource[form3.answer][index][key] = event;
      return [...prevResource];
    });
  };
  const handleImageUrl = (index, url, key) => {
    const newSubForm = [...resource];
    newSubForm[key][index].image = url;
    setResource(newSubForm);
  };
  const handleFileInputMultiple = (index, file) => {
    setResource((prevResources) => {
      const newResources = [...prevResources];
      newResources[form3.answer][index].image.added_image = file;
      return newResources;
    });
  };
  const handleUploadImages = async (callback) => {
    for (let key = 0; key < resource.length; key++) {
      const promises = [];
      resource[key].forEach((item, index) => {
        const imageId = item?.image?.form_unique_id ?? "";
        let image = item?.image?.added_image;
        if (image instanceof File) {
          const formData = new FormData();
          formData.append("step_id", stepId);
          formData.append("sub_step_id", data?._id);
          formData.append("template_id", templateId);
          formData.append("form_unique_id", imageId);
          formData.append("image", image);
          promises.push(
            addImagesMutateAsync(formData).then((response) => ({
              index,
              response,
            }))
          );
        }
      });

      try {
        const responses = await Promise.all(promises);
        const allSuccessful = responses.every(
          (response) => response.response.statusCode === 200
        );
        if (allSuccessful) {
          responses.forEach((response) =>
            handleImageUrl(response.index, response.response.data, key)
          );
        } else {
          toast.error("Some images failed to upload");
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
      if (key === resource.length - 1) {
        callback();
      }
    }
  };

  const uploadFormJson = async (formData) => {
    const params = {
      step_id: stepId,
      sub_step_id: data?._id,
      template_id: templateId,
      template_json: formData,
    };

    try {
      const response = await addTemplateMutateAsync(params);

      if (response.statusCode === 200) {
        refetchStepDetail();
        toast.success(response?.message);
        nav(RoutePath.SKILL_DETAIL.path);
        handleEditForm();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error?.response.data?.message);
    }
  };
  const handelApply = async () => {
    let dataResource = resourceSubForm;
    const formData = [
      {
        name: data?.name ?? "Reflection",
        form_json: [
          form1,
          form2,
          form3,
          { ...dataResource[0], resources: resource[0] },
          { ...dataResource[1], resources: resource[1] },
          { ...dataResource[2], resources: resource[2] },
          { ...dataResource[3], resources: resource[3] },
        ],
      },
    ];

    uploadFormJson(formData);
  };

  const previewImageHandler = (item) => {
    if (item?.image?.added_image === "") {
      return ImagesUrl.shape_image_square;
    } else if (item?.image?.added_image instanceof File) {
      return URL.createObjectURL(item?.image.added_image);
    } else {
      return `https://leadherself-api.csdevhub.com/src/public/form_images/${item?.image.added_image}`;
    }
  };

  const handelEditorModal = () => {
    setEditorModal(!editorModal);
  };
  useEffect(() => {
    if (data?.template_id == 5) {
      const forms = data?.form_json;
      let resourceSubFormValue = [];
      let resourceValue = [];

      for (let i = 3; i < 7; i++) {
        resourceSubFormValue?.push({
          formId: forms[0]?.form_json[i]?.formId,
          resourceKey: forms[0]?.form_json[i]?.resourceKey,
          formType: forms[0]?.form_json[i]?.formType,
          title: forms[0]?.form_json[i]?.title,
          buttonText: forms[0]?.form_json[i]?.buttonText,
        });
        resourceValue?.push(forms[0]?.form_json[i]?.resources);
      }

      if (forms && forms?.length !== 0) {
        setForm1(forms[0]?.form_json[0]);
        setForm2(forms[0]?.form_json[1]);
        setForm3(forms[0]?.form_json[2]);
        setResourceSubForm([...resourceSubFormValue]);
        setResource([...resourceValue]);
      } else {
        handleEditForm(true);
      }
    }
  }, [, refetchStepDetail]);

  return (
    <TempLayout
      handleEditForm={handleEditForm}
      editTemplate={editTemplate}
      handleApply={() => handleUploadImages(handelApply)}
      loading={isLoadingAddTemplate}
    >
      <section className="screen-section">
        <div className="container-fluid gx-0">
          <div className="row gap-3">
            <div className="col-12">
              <div className="screen-wrapper">
                <ScreenLayout name={data.name}>
                  <div className="screen-body">
                    <div
                      className="checkbox-wrapper"
                      style={{ maxHeight: "395px", marginTop: "0px" }}
                    >
                      <div className="checkbox-list">
                        <div className="screen-content">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setForm1({
                                ...form1,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {form1.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-text-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setForm1({
                            ...form1,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {form1.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
                <ScreenLayout name={data.name}>
                  <div className="screen-body">
                    <div
                      className="checkbox-wrapper"
                      style={{ maxHeight: "395px", marginTop: "0px" }}
                    >
                      <div className="checkbox-list">
                        <div className="screen-content">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setForm2({
                                ...form2,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {form2.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setForm2({
                            ...form2,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {form2.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
                <ScreenLayout name={data.name}>
                  <div className="screen-body px-35">
                    <div
                      className="checkbox-wrapper"
                      style={{ maxHeight: "395px", marginTop: "0px" }}
                    >
                      <div className="checkbox-list">
                        <div className="bold-font-style">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setForm3({
                                ...form3,
                                question: e.target.innerText,
                              });
                            }}
                          >
                            {form3?.question}
                          </p>
                        </div>
                        <div className="checkbox-wrapper">
                          {form3?.options?.map((item, index) => {
                            return (
                              <div className="checkbox-list" key={index}>
                                <input
                                  type="radio"
                                  className="d-none"
                                  name="listradio"
                                  id={`checkbox_1${index}`}
                                  checked={index == form3?.answer}
                                  onChange={(e) => {
                                    setForm3({
                                      ...form3,
                                      answer: index, // update the answer with the correct index
                                    });
                                    setActive(0);
                                  }}
                                />
                                <label htmlFor={`checkbox_1${index}`}>
                                  <span className="check-box-icon" />
                                  <span
                                    className="check-box-text"
                                    contentEditable={editTemplate}
                                    onBlur={(e) => {
                                      const newOptions = [...form3.options]; // create a copy of the options array
                                      newOptions[index] = e.target.innerText; // update the option at the current index
                                      setForm3({
                                        ...form3,
                                        options: newOptions,
                                      }); // update the form3 state
                                    }}
                                  >
                                    {item}
                                  </span>
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setForm3({
                            ...form3,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {form3.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
                <ScreenLayout name={data.name}>
                  <div className="sm-content">
                    <p
                      contentEditable={editTemplate}
                      onBlur={(e) =>
                        handleResourceSubFormText(e, form3.answer, "title")
                      }
                    >
                      {resourceSubForm[form3?.answer].title}
                    </p>
                  </div>
                  <div className="screen-body">
                    <div className="add-item-blk">
                      <button
                        className="add-item-btn"
                        onClick={editTemplate ? addNewResource : null}
                      >
                        Add More
                      </button>
                    </div>
                    <div className="resources-wrapper">
                      {resource[form3?.answer]?.map((res, i, array) => {
                        return (
                          <ReflectionComponent
                            editTemplate={editTemplate}
                            index={i}
                            item={res}
                            setActive={setActive}
                            active={active}
                            removeResource={() => removeResource(i)}
                            handleResourceText={handleResourceText}
                            handleFileInputMultiple={handleFileInputMultiple}
                            isLoadingAddImages={isLoadingAddImages}
                            array={array}
                          />
                        );
                      })}
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) =>
                          handleResourceSubFormText(
                            e,
                            form3.answer,
                            "buttonText"
                          )
                        }
                      >
                        {resourceSubForm[form3.answer].buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
                <ScreenLayout notHeaderRequire={true}>
                  <img
                    src={previewImageHandler(resource[form3.answer][active])}
                    style={{
                      padding: "15px 10px 0px 10px",
                      maxHeight: "200px",
                      objectFit: "contain",
                    }}
                    alt="..."
                  />
                  <div
                    className="checkbox-wrapper"
                    style={{ maxHeight: "395px", marginTop: "9px" }}
                  >
                    <div className="checkbox-list">
                      <div className="screen-body">
                        <div
                          className="resources-wrapper"
                          style={{ maxHeight: "200px", marginTop: "-1 px" }}
                        >
                          <div className="d-flex justify-content-center">
                            <h6
                              className="justify-content-center"
                              contentEditable={editTemplate}
                              onBlur={(e) =>
                                handleResourceText(e, active, "overViewTitle")
                              }
                            >
                              {resource[form3?.answer][active]?.overViewTitle}
                            </h6>
                          </div>
                          <div
                            onClick={() => handelEditorModal()}
                            dangerouslySetInnerHTML={{
                              __html:
                                resource[form3?.answer][active]?.overViewDes,
                            }}
                          ></div>

                          <div class="reflection-item w-100 note-text-div">
                            <div class="reflection-quote">
                              <p
                                className="feedback-inner-text"
                                style={{
                                  fontSize: "12px",
                                  color: "#006972",
                                }}
                                contentEditable={editTemplate}
                                onBlur={(e) =>
                                  handleResourceText(e, active, "overViewNote")
                                }
                              >
                                {resource[form3.answer][active]?.overViewNote}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div
                      className="btn-text-style py-0"
                      contentEditable={editTemplate}
                      onBlur={(e) =>
                        handleResourceText(e, active, "overViewPrimaryButton")
                      }
                    >
                      {resource[form3.answer][active]?.overViewPrimaryButton}
                    </div>
                    <div
                      className="footer-nav-btn"
                      style={{ padding: "13px 0px 0px 0px" }}
                    >
                      <div className="d-flex flex-column w-100">
                        <div
                          className="btn-style"
                          contentEditable={editTemplate}
                          onBlur={(e) =>
                            handleResourceText(e, active, "overViewButtonText")
                          }
                        >
                          {resource[form3.answer][active]?.overViewButtonText}
                        </div>
                        <div class="link-blk mt-2">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) =>
                              handleResourceText(e, active, "learnMoreLink")
                            }
                          >
                            <a>
                              {resource[form3.answer][active]?.learnMoreLink}
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
              </div>
              <CustomModal
                open={editorModal}
                title=""
                cancelTitle="save"
                actionButtonClass="bg-danger"
                isLoading={false}
                onClose={() => handelEditorModal()}
                actionButtonNotRequire={true}
              >
                <TextEditor
                  key={active}
                  index={active}
                  stateKey="overViewDes"
                  value={resource[form3.answer][active]?.overViewDes}
                  handleChange={handleTextEditorResourceText}
                />
              </CustomModal>
            </div>
          </div>
        </div>
      </section>
    </TempLayout>
  );
};

export default Reflection;
