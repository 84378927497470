import React from "react";
import Skeleton from "react-loading-skeleton";

const DetailSkeleton = () => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="row gy-3">
          <div className="col-12">
            <div className="d-flex justify-content-end align-items-center gap-1"></div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-sm-4 col-lg-3">
                <Skeleton count={1} className="full-image-blk" />
              </div>

              <div className="col-sm-8 col-lg-9">
                <Skeleton
                  style={{ height: "17%", width: "60%", marginBottom: "20px" }}
                />
                <Skeleton className="col-sm-4 col-lg-3" />
                <Skeleton className="col-sm-4 col-lg-3" />
                <Skeleton className="col-sm-4 col-lg-3" />
                <Skeleton className="col-sm-4 col-lg-3" />
                <Skeleton className="col-sm-4 col-lg-3" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailSkeleton;
