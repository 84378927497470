/* eslint-disable import/no-anonymous-default-export */
import Journal from "./journal";
import Scenario from "./scenario";
import Actions from "./actions";

const TemplateExerciseIndex = ({
  index,
  data,
  exerciseId,
  handleEditForm,
  editTemplate,
  dataLoading,
  refetchStepDetail,
}) => {
  const templateId = index;
  const props = {
    templateId,
    data,
    exerciseId,
    handleEditForm,
    editTemplate,
    dataLoading,
    refetchStepDetail,
  };
  const tempArr = [
    <Scenario props={props} />,
    <Journal props={props} />,
    <Actions props={props} />,
  ];

  return tempArr[index - 1];
};

export default TemplateExerciseIndex;
