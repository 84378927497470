import React from "react";

const JournalComponent = ({
  index,
  handleJournalText,
  item,
  editTemplate,
  removeJournal,
  color,
  array,
}) => {
  return (
    <div class="reflection-item" key={index} style={{ backgroundColor: color }}>
      <div class="reflection-title">
        <p
          contenteditable={editTemplate ? "true" : "false"}
          onBlur={(e) => handleJournalText(e, index, "title")}
        >
          {item?.title}
        </p>
      </div>
      <div class="reflection-quote">
        <p
          contenteditable={editTemplate ? "true" : "false"}
          onBlur={(e) => handleJournalText(e, index, "description")}
        >
          {item?.description}
        </p>
      </div>
      {(array?.length > 1 || index > 0) && editTemplate ? (
        <button
          class="del-reflection-item"
          onClick={() => removeJournal(index)}
        >
          <i class="fa-solid fa-trash-can"></i>
        </button>
      ) : null}
    </div>
  );
};

export default JournalComponent;
