import React from "react";
import Skeleton from "react-loading-skeleton";

const SkillListingSkeleton = () => {
  return (
    <ul className="list-group w-100">
      {[1, 2, 3, 4, 5].map((item, index) => {
        return (
          <Skeleton
            style={{ width: "1", height: "150px", marginBottom: "10px" }}
          />
        );
      })}
    </ul>
  );
};

export default SkillListingSkeleton;
